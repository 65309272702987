import {Component, computed, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {initFlowbite} from 'flowbite';
import {Subscription} from "rxjs";
import {LoadingService, NavigationComponent, NotificationService} from "./components";
import {DotAnimationComponent} from "./core/components/loading/dot-animation/dot-animation.component";
import {NotificationAlertComponent} from "./core/components/notification/notification-alert.component";
import {DotLoadingServices} from "./core/services/dot-loading.Services";
import {AuthService} from "./core/services/auth.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, DotAnimationComponent, NotificationAlertComponent, NavigationComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit ,OnDestroy{

  currentNotification = computed(() => this.notificationService.notification());
  private subscriptions: Subscription = new Subscription();
  title = 'Gate Pass System';
  isLoading = computed(() => this.dotLoadingServices.isLoading());


  hideOnPages: boolean = false;

  constructor(
      private loadingService: LoadingService,
      private router: Router,
      public dotLoadingServices: DotLoadingServices,
      private notificationService: NotificationService,
      protected authService: AuthService
  ) {
  }


  ngOnInit(): void {
    initFlowbite();
    this.subscriptions.add(
        this.loadingService.isLoading.subscribe((isLoading) => {
          this.dotLoadingServices.setLoading(isLoading);
        })
    );

    this.subscriptions.add(
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.hideOnPages = this.router.url.includes('/login' && '/error' && '/authenticate');
          }
        })
    );
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  clearNotification() {
    const notification = this.currentNotification();
    if (notification) {
      this.notificationService.clearNotification(notification.id);
    }
  }
}