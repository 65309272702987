import {Routes} from '@angular/router';
import {AuthGuard} from "./core/services/auth-guard.service";

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'view-gate-pass',
        pathMatch: 'full'
    },
    {
        path: 'create-gate-pass',
        loadComponent: () => import('./components/index').then(com => com.CreateGatePassComponent),
        canActivate: [AuthGuard], data: { requiredAction: 'create' }
    },
    {
        path: 'view-gate-pass',
        loadComponent: () => import('./components/index').then(com => com.GatePassGridComponent),
        canActivate: [AuthGuard]
    },
    {
        path: 'return-gate-pass',
        loadComponent: () => import('./components/index').then(com => com.ReturnGatePassComponent),
        canActivate: [AuthGuard], data: { requiredAction: 'return' }
    },
    {
        path: 'error',
        loadComponent: () => import('./components/index').then(com => com.ErrorPageComponent),
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: '/view-gate-pass'
    }

];